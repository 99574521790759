import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserInfoModel } from '../user-info-model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LengthDatatableService {
    public length: Subject<number>  = new BehaviorSubject<number>(0);
    currentAttachement = this.length.asObservable();
    constructor(private http: HttpClient) { }

    changeLength(length: number) {
        this.length.next(length);
    }

    get_search_count(domain: any, object: any) {
        return this.http.get(`${environment.URL_BASE}/object/${object}/search_count?domain=${domain}`);
    }
}
