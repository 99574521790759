export class UserInfoModel {
    // tslint:disable-next-line:variable-name
    public company_id: number;
    public id: number;
    public email: string;
    public name: string;
    // tslint:disable-next-line:variable-name
    public company_name: string;
    public company_ids: Array<any>;
    role: string;
    phone: any;
    address: string;
    zip:number;
    city:string;
    imageUrl:string;
    cin:string;
    apartement_list_ids_get:string;

    // tslint:disable-next-line:max-line-length
    constructor(id: number, name: string, email: string, company_id: number, company_name: string, company_ids: Array<any>, role: string, address: string, phone: any,city:string,zip:number) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.company_id = company_id;
        this.company_name = company_name;
        this.company_ids = company_ids;
        this.role = role;
        this.address = address;
        this.phone = phone;
        this.city = city;
        this.zip =zip;
    }
}

export enum RoleUser {
    admin = 'admin',
    back_office = 'back_office',
    front_office_technical = 'front_office_technical',
    front_office_recovery = 'front_office_recovery',
    staff = 'staff',
    owner_president = 'owner_president',
    owner_treasury = 'owner_treasury',
    owner = 'owner'
}
