// tslint:disable-next-line:label-position
const unauthorizedPagesStaff = [
    '/comptabilite_finance/detail_gestion_budgetaire',
    '/comptabilite_finance/edit_ordre_paiement',
    '/comptabilite_finance/tableau_recouvrement',
    '/comptabilite_finance/rapport',
    '/comptabilite_finance/etat_caisse',
    '/comptabilite_finance/tableau_recouvrement/detail-resident',
    '/comptabilite_finance/rapport_journalier',
    '/contentieux/tableau_contentieux',
    '/pointage/attribution_conge_autorisation',
    '/pointage/autorisation',
    '/pointage/suivi_conge',
    '/resident/residents',
    '/pointage/planning',
    '/profile/gestion_profile'
];

export default unauthorizedPagesStaff;
