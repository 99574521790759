import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
  RouterEvent
} from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { HomeService } from "./providers/home.service";
import { AuthServiceService } from "./auth/services/auth-service.service";
import { UserInfoService } from "./providers/user.info.service";
import { UserInfoModel, RoleUser } from "./user-info-model";
import { environment } from "src/environments/environment";
import { filter, takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { locale } from "moment";
import { randomString } from "./randomString";
import { ReclamationService } from './providers/reclamation.service';
import { isEmptyObject } from './empty-object';
import { isArray } from 'util';
import { LengthDatatableService } from './providers/length-datatable.service';
import { DocumentsService } from './providers/documents.service';
import * as moment from 'moment';
import localeFr from "@angular/common/locales/fr";
import { registerLocaleData } from "@angular/common";

registerLocaleData(localeFr);
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  public destroyed = new Subject<any>();
  currentUrl: string;
  showLoadingIndicatior = true;
  imageCompany: any;
  listResidences: any[];
  imageUser: string;
  badgeCount :number =0;
  create_date:string;


  // urlCompanyImages: string;
  residenceName: string;
  userName: string;
  roleUser: string;
  authorizedComptabilite: boolean = false;
  authorizedReclamationDemande: boolean = false;
  authorizedDocuments: boolean = false;
  authorizedContentieux: boolean = false;
  authorizedPointage: boolean = false;
  authorizedGestonProfiles: boolean = false;
  authorizedPointagePlanning: boolean = false;
  authorizedPointageEmployees: boolean = false;
  authorizedPointagetaches:boolean=false;
  authorizeddocumentemploye:boolean=false;
  authorizedemployee :boolean=false;
  authorizedDocs : boolean = false;
  authorizedDocsres : boolean = false ;
  authorizedrecadmin : boolean = false ;
  userId: number;
  randomString: string;
  authorizedrec: boolean = false;
  authorizedbudget : boolean = false;
  authorizedrecouvrement : boolean = false;
  authorizedOP : boolean = false;
  authorizedCaisse : boolean =false;
  authorizedrappjour : boolean =false;
  authorizedPoint : boolean =false;
  authorizedtachesemploye :boolean =false;
  authorizedlisteresident :boolean =false;
  authorizednotif: boolean=false;
  authorizedespaceresident :boolean=false;
  authorizedtabpointage :boolean =false;
  authorizednotifpers :boolean =false;
  authorizedconge :boolean=false;
  listrequest: Array<any> = [];
  nombreheure: any;
  res_id:number;
  // tslint:disable-next-line:max-line-length
  constructor(
    private _router: Router,
    location: PlatformLocation,
    private homeService: HomeService,
    private authService: AuthServiceService,
    public userInfoService: UserInfoService,
    public reclamationservice :ReclamationService,
    public table : LengthDatatableService,
    public documentsService : DocumentsService,
    public router:Router
  ) {
    this.randomString = randomString();

    // this.fetchDataUser();
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicatior = true;
        location.onPopState(() => {
          // window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }

      // tslint:disable-next-line:max-line-length
      if (
        this.currentUrl.trim() === "signin" ||
        this.currentUrl.trim() === "signup" ||
        this.currentUrl.trim() === "forgot-password" ||
        this.currentUrl.trim() === "locked" ||
        this.currentUrl.trim() === "page404" ||
        this.currentUrl.trim() === "page500" ||
        this.currentUrl.trim() === "reset-password" ||
        this.currentUrl.trim().split("?")[0] === "new-password" ||
        this.currentUrl.trim() === "home_page" ||
        this.currentUrl.trim() === "resident/residentForm"
      ) {
        document.getElementById("main-component").style["display"] = "none";
      } else {
        document
          .getElementById("main-component")
          .style.removeProperty("display");
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicatior = false;
      }
    });
  }
  ngOnInit() {
    console.log("timestamp ===== ", new Date().getTime());
    // this.fetchDataUser();
    // this.urlCompanyImages = environment.URL_BASE + '/image_company/';
    this._router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
      )
      .subscribe(() => {
        this.fetchDataUser();
      });


    this.reclamationservice.refreshNeeded
      .subscribe(()=>{
        this.fetchDataUser();
      });
  }

  urlCompanyImages(id) {

    return `${environment.URL_BASE}/image_company/${id}/${this.randomString}`;
  }


  urluserImages(id) {
    return `${environment.URL_BASE}/image_user/${id}/${this.randomString}`;
  }


  redirecttype(ticket_type:string) {
    if(ticket_type=="reclamation") {
      this.router.navigate(['/reclamations_demandes/suivi_les_reclamations'])
   
    } else {
      this.router.navigate(['/reclamations_demandes/suivi_les_demandes'])
    
    }
  }
  change_residence(userId: number, companyId: number, companyName: string) {
    console.log(
      "change_residence + companyId :",
      companyId,
      "companyName : ",
      companyName
    );
    this.homeService
      .change_current_residence(userId, companyId)
      .subscribe(company => {
        this.userInfoService.changeCompanyId(companyId);
        this.userInfoService.changeInfoCompanyUser(companyId, companyName);
      });
  }


 getrecdemnotif(role :string, company_id:number, id:number) {
  var domain: any;

   // tslint:disable-next-line: max-line-length
   if ( role === RoleUser.admin || role === RoleUser.front_office_technical || role === RoleUser.back_office ) {
    domain = `[('company_id', '=', ${company_id}), ('state','=','ouvert')]`;}
     else {
    domain = `[('company_id', '=', ${company_id}),('create_uid', '=', ${id}),('state','!=', 'ouvert')]`;
 }
// tslint:disable-next-line: align
this.reclamationservice.get_rec_dem(domain).subscribe(data =>{
  this.reclamationservice.get_list_count(domain).pipe(takeUntil(this.destroyed)).subscribe(
    data => {
      console.log("log lenthtable", data)
      this.badgeCount = data['count']
    }
  );
  const listrq=[];
  let list =data['support.ticket'];
  console.log("khbkfblfbvd", list);
  if (isArray(list)) {
                list.forEach(element => {
                  listrq.push({
                    id: element.id,
                    person_name: element.person_name,
                    subject: element.subject,
                    create_date: element.create_date,
                    create_user_id: element.create_uid[0],
                    ticket_type: element.ticket_type,
                    number : element.ticket_number,
                    state: element.state

                  });
                });
              } else if (!isEmptyObject(list)) {
          listrq.push({
                    id: list.id,
                    person_name: list.person_name,
                    subject: list.subject,
                    create_date: list.create_date,
                    create_user_id: list.create_uid[0],
                    ticket_type:list.ticket_type,
                    number : list.ticket_number,
                    state: list.state

                  });
                }
          
  this.listrequest = listrq;
    });

  }

  clearCount() {

    this.badgeCount = 0;
  }

  stateDesign(state) {
    if (state === 'encours') {
      return '<span class="label bg-green " style="width: 84px;text-align: center;">En cours</span>';
    } else if (state === 'ouvert') {
      return '<span class="label bg-blue " style="width: 84px;text-align: center;">Ouvert</span>';
    } else if (state === 'devis') {
      return '<span class="label bg-yellow " style="width: 84px;text-align: center;">Devis</span>';
    } else if (state === 'bloque') {
      return '<span class="label label-warning  " style="width: 84px;text-align: center;">Bloquée</span>';
    } else if (state === 'cloture') {
      return '<span class="label bg-red " style="width: 84px;text-align: center;">Clôturée</span>';
    }
  }
  
  logout(userId) {
    this.authService.logout(userId).subscribe(data => {
      console.log("logout success");
      console.log(data);
      this.userInfoService.InfoUserAfterLogout();
      console.log("After logout========");
      // this._router.navigate(["/authentication/home_page"]);
      location.href = "/authentication/home_page";
    });
  }

  fetchDataUser() {
    this.userInfoService.currentInfoUser.subscribe(dataUser => {
      if (dataUser === null) {
        this.homeService.get_user_info().subscribe(data => {
          // tslint:disable-next-line:max-line-length
          const user = new UserInfoModel(
            data.id,
            data.name,
            data.email,
            data.company_id,
            data.company_name,
            data.company_ids,
            data.role,
            data.address,
            data.phone,
            data.city,
            data.zip
          );
          this.listResidences = user.company_ids;
          this.userInfoService.changeInfoUser(user);
          this.userInfoService.changeUserId(user.id);
          this.userInfoService.changeUserName(user.name);
          this.userInfoService.changeUserMail(user.email);
          this.userInfoService.changeCompanyId(user.company_id);
          this.userInfoService.changeCompanyName(user.company_name);
          this.userName = user.name;
          this.userId = user.id;
          this.roleUser = user.role;
          this.residenceName = user.company_name;
          this.imageCompany = `${environment.URL_BASE}/image_company/${user.company_id}/${this.randomString}`;
          this.imageUser = `${environment.URL_BASE}/image_user/${user.id}/${this.randomString}`;
          this.hideMenu(data.role);
          this.getrecdemnotif(data.role, data.company_id, data.id);

        });
      } else {
        // tslint:disable-next-line:max-line-length
        const user = new UserInfoModel(
          dataUser.id,
          dataUser.name,
          dataUser.email,
          dataUser.company_id,
          dataUser.company_name,
          dataUser.company_ids,
          dataUser.role,
          dataUser.address,
          dataUser.phone,
          dataUser.city,
          dataUser.zip
        );
        this.listResidences = user.company_ids;
        this.userName = user.name;
        this.userId = user.id;
        this.residenceName = user.company_name;
        this.imageCompany = `${environment.URL_BASE}/image_company/${user.company_id}/${this.randomString}`;
        this.imageUser = `${environment.URL_BASE}/image_user/${user.id}/${this.randomString}`;
        this.hideMenu(dataUser.role);
        this.getrecdemnotif(dataUser.role, dataUser.company_id, dataUser.id);

      }
    });
  }

  hideMenu(role: string) {
    this.initHideMenu();
    if (role !== "") {
      if (role === RoleUser.admin) {
        this.authorizedComptabilite = true;
        this.authorizedReclamationDemande = true;
        this.authorizedDocuments = true;
        this.authorizedContentieux = true;
        this.authorizedPointage = true;
        this.authorizedGestonProfiles = true;
        this.authorizedPointageEmployees = true;
        this.authorizedPointagePlanning = true;
        this.authorizedrec= true;
        this.authorizedtachesemploye =true;
        this.authorizedDocs = true;
        this.authorizedbudget = true ;
        this.authorizedOP =true;
        this.authorizedrecouvrement = true;
        this.authorizedCaisse  =true;
        this.authorizedrappjour = true;
        this.authorizedPoint =true;
        this.authorizedemployee = true;
        this.authorizedlisteresident = true;
        this.authorizednotif=true;
        this.authorizedtabpointage =true;
        this.authorizedconge=true;

      } else if (
        role === RoleUser.front_office_technical
      ) {
        this.authorizedReclamationDemande = true;
        this.authorizedPointage = true;
        this.authorizedtabpointage =true;
        this.authorizedPointagePlanning = true;
        this.authorizedemployee = true;
        this.authorizedrec=true ;
        this.authorizedtachesemploye =true;
        this.authorizednotif=true;


      } else if (role === RoleUser.staff) {
        this.authorizeddocumentemploye = true;
        this.authorizedReclamationDemande = true;
        this.authorizedPointagetaches = true;
        this.authorizedPointage = true;
        this.authorizedrec =true ;
        this.authorizedPoint =true;
        this.authorizedconge =true;
        this.authorizednotifpers =true;

      } else if (role === RoleUser.front_office_recovery) {
        this.authorizedComptabilite = true;
        this.authorizedReclamationDemande = true;
        this.authorizedrec=true ;
        this.authorizedDocuments = true;
        this.authorizedDocs = true;
        this.authorizedrecouvrement = true;
        this.authorizedContentieux = true;
        this.authorizednotifpers =true;


      } else if(role === RoleUser.owner_treasury) {
          this.authorizedDocuments = true;
          this.authorizedDocsres = true;
          this.authorizedReclamationDemande = true;
          this.authorizedrec=true ;
          this.authorizedDocs = true;
          this.authorizedrecouvrement = true;
          this.authorizedContentieux = true;
          this.authorizedOP  = true;
          this.authorizedbudget = true;
          this.authorizedComptabilite = true;
          this.authorizedespaceresident =true;

          this.authorizednotifpers =true;



        } else if (role === RoleUser.owner_president) {
          this.authorizedDocuments = true;
          this.authorizedDocsres = true;
          this.authorizedReclamationDemande = true;
          this.authorizedrec =true ;
          this.authorizedDocs = true;
          this.authorizedrecouvrement = true;
          this.authorizedContentieux = true;
          this.authorizedOP  = true;
          this.authorizedbudget = true;
          this.authorizedComptabilite = true;
          this.authorizedespaceresident =true;
          this.authorizednotifpers =true;

       
        } else if (role === RoleUser.owner) {
        this.authorizedComptabilite = true;
        this.authorizedReclamationDemande = true;
        this.authorizedDocsres = true;
        this.authorizedDocs = true;
        this.authorizedDocuments = true;
        this.authorizedrec =true ;
        this.authorizedespaceresident=true;
        this.authorizednotifpers =true;

      
      } else if (role === RoleUser.back_office) {
        this.authorizedComptabilite = true;
        this.authorizedReclamationDemande = true;
        this.authorizedDocuments = true;
        this.authorizedContentieux = true;
        this.authorizedPointage = true;
        this.authorizedGestonProfiles = true;
        this.authorizedPointageEmployees = true;
        this.authorizedPointagePlanning = true;
        this.authorizedrec= true;
        this.authorizedtachesemploye =true;
        this.authorizedDocs = true;
        this.authorizedbudget = true ;
        this.authorizedOP =true;
        this.authorizedrecouvrement = true;
        this.authorizedCaisse  =true;
        this.authorizedrappjour = true;
        this.authorizedPoint =true;
        this.authorizedemployee = true;
        this.authorizedlisteresident = true;
        this.authorizednotif=true;
        this.authorizedtabpointage =true;
        this.authorizedconge=true;
      }
    }
  }
  initHideMenu() {
    this.authorizedComptabilite = false;
    this.authorizedReclamationDemande = false;
    this.authorizedDocuments = false;
    this.authorizedContentieux = false;
    this.authorizedPointage = false;
    this.authorizedGestonProfiles = false;
    this.authorizedPointagePlanning = false;
    this.authorizedPointageEmployees = false;
    this.authorizedPointagetaches = false;
    this.authorizeddocumentemploye = false;
    this.authorizedemployee = false; 
    this.authorizedDocs = false;
    this.authorizedDocsres = false;
    this.authorizedrec = false;
    this.authorizedrecadmin = false;
    this.authorizedbudget = false;
    this.authorizedrecouvrement = false;
    this.authorizedOP  = false;
    this.authorizedCaisse  =false;
    this.authorizedrappjour = false;
    this.authorizedPoint =false;
    this.authorizedtachesemploye =false;
    this.authorizedlisteresident= false;
    this.authorizednotif=false;
    this.authorizedespaceresident=false;
    this.authorizedtabpointage =false;
    this.authorizedconge =false;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
