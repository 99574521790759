import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserInfoModel } from '../user-info-model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  get_user_info() {
    const httpOptions = {
      headers: new HttpHeaders({
        'access_token': localStorage.getItem('JWT_TOKEN')
      })
    };
    return this.http.get<UserInfoModel>(`${environment.URL_BASE}/user_info`);

  }

  get_image_company() {
    const Headers = new HttpHeaders ({
        'access_token': localStorage.getItem('JWT_TOKEN'),
        observe: 'response',
        responseType: 'blob'
      });

    return this.http.get(`${environment.URL_BASE}/image_company`, { headers: Headers, responseType: 'blob'});

  }

  get_image_user() {
    const httpOptions = {
      headers: new HttpHeaders({
        'access_token': localStorage.getItem('JWT_TOKEN'),
        'Content-Type': 'image/png'
      })
    };
    return this.http.get(`${environment.URL_BASE}/image_user`);

  }

  change_current_residence(userId: number, companyId: number) {
    return this.http.post(`${environment.URL_BASE}/object/res.users/${userId}?vals={'company_id': ${companyId}}`, null);
  }
}
