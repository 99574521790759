
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, tap, map, switchMap, delay, finalize } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Tokens } from '../models/tokens';
import { variable } from '@angular/compiler/src/output/output_ast';
import { ErrorMessage } from './login-error-message';
import { Router } from '@angular/router';
import { errorHandler } from '@angular/platform-browser/src/browser';
import { HomeGuardGuard } from '../guards/home-guard.guard';
import { firstLogout } from '../unauthorized-pages-by-role/increment';


@Injectable({
  providedIn: "root"
})
export class AuthServiceService {
  private client = "xmq8S9zAhdVVsP7bEIuVf8N3yeU97Rzo.app.odoousercontent.com";
  private readonly JWT_TOKEN = "JWT_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly CODE = "CODE";
  private loggedUser: string;
  private resetUrl = "/authentication/new-password";

  private homeUrl = "/authentication/home_page";
  authorizationCode = "";
  httpOptionsLogin: any;

  constructor(private http: HttpClient, public router: Router) {}

  authorize() {
    const httpOptions = {
      headers: new HttpHeaders({
        client_id: environment.CLIENT_ID
      })
    };
    return this.http.get(
      `${environment.URL_BASE}/oauth2/authorize`,
      httpOptions
    );
  }

  login(login: string, password: string, database: string, code: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        client_id: environment.CLIENT_ID,
        client_secret: environment.CLIENT_SECRET,
        grant_type: "authorization_code",
        code: code
      })
    };
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);
    formData.append("database", database);
    return this.http
      .post(
        `${environment.URL_BASE}/oauth2/access_token`,
        formData,
        httpOptions
      )
      .pipe(
        tap(data => {
          console.log("data login ::::::: ");
          console.log(data);
          this.doLoginUser(login, data);
        }),
        map(data => data)
      );
    // ,
    // catchError(error => {
    //   console.log('throwError :: ', error);
    //   return throwError(error);
    // }),
    // catchError(error => {
    //   console.log(error);
    //   let messageError: string;
    //   if (error.status === 401) {
    //     messageError = ErrorMessage.unauthorized_user;
    //   } else {
    //     messageError = ErrorMessage.error_connexion;
    //   }
    //   return of(messageError);
    // }),
    // finalize(() => console.log("second finalize() block executed")));
  }

  logout(userId: any) {
    firstLogout();
    const httpOptions = {
      headers: new HttpHeaders({
        refresh_token: this.getRefreshToken()
      })
    };

    const formData = new FormData();
    formData.append('user_id', userId);
    return this.http.post(`${environment.URL_BASE}/logout`, formData, httpOptions).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        grant_type: "refresh_token",
        refresh_token: this.getRefreshToken()
      })
    };

    return this.http.post(`${environment.URL_BASE}/refresh_token`, null, httpOptions).pipe(tap(data => {
      this.storeJwtToken(data['access_token']);
    }, error => {
      this.removeTokens();
      if (this.router.url.split('?')[0] === this.resetUrl) {
        this.router.navigateByUrl(this.router.url);
      } else if (this.router.url === this.homeUrl) {
        this.router.navigateByUrl('/authentication/home_page');
      } else {
        this.router.navigateByUrl('/authentication/home_page');
      }
    }
    ));
  }
  getCode() {
    this.storeCode();
    while (true) {
      if (this.authorizationCode !== "") {
        break;
      }
    }
    return this.authorizationCode;
  }
  storeCode() {
    this.authorize().subscribe(code => {
      this.authorizationCode = code["code"];
      // localStorage.setItem(this.CODE, code['code']);
    });
  }
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: any) {
    this.loggedUser = username;
    // tslint:disable-next-line:prefer-const
    var token: Tokens = new Tokens();
    token.jwt = tokens["access_token"];
    token.refreshToken = tokens["refresh_token"];
    console.log(JSON.stringify(token));
    this.storeTokens(token);
  }

  private doLogoutUser() {
    console.log("do log out  ======= ");
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(environment.CLIENT_ID);
    localStorage.removeItem(environment.DATABASE);
    localStorage.removeItem(environment.CLIENT_SECRET);
    sessionStorage.clear();
    console.log("remove==================");
  }

  /*Meee */
  requestReset(body) {
    return this.http.post(`${environment.URL_BASE}/reset-password`, body);
  }
  newPassword(
    resetToken: any,
    login: string,
    newPass: any,
    confirmNewPass: any
  ) {
    return this.http.post(
      `${environment.URL_BASE}/reset_password?token=${resetToken}&login=${login}
                            &password=${newPass}&confirm_password=${confirmNewPass}`,
      null
    );
  }
  forgotPassword(login) {
    return this.http.post(
      `${environment.URL_BASE}/forgot_password?login=${login}`,
      null
    );
  }
  navigateUrl(url: string) {
    return url;
  }
}
