import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { DocumentOwner } from './../pages/documents/document-owner';
import { LengthDatatableService } from './length-datatable.service';
import { tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  public documentOwner: Subject<DocumentOwner> = new BehaviorSubject<DocumentOwner>(null);
  currentidDocumentOwner = this.documentOwner.asObservable();
  constructor(private http: HttpClient,
    private lengthTable: LengthDatatableService) { }
  _refreshNeeded$ = new Subject<void>();

  get refreshNeeded() {
    return this._refreshNeeded$;
  }


  get_users_documents(companyId: number, offset: number, size: number) {
     const domain = `[('company_id','=',${companyId}), ('is_resident','=',True)]`;
    // this.lengthTable.get_search_count(domain, 'res.users').pipe(take(1)).subscribe(
    //   data => this.lengthTable.changeLength(data['count'])
    // );
    return this.http.get(`${environment.URL_BASE}/object/res.users?domain=${domain}&fields=['name','phone','email','cin','apartement_list_ids_get','street','city','zip']&offset=${offset}&limit=${size}`);
  }



  getUserResident(companyId: number) {
    return this.http.get(`${environment.URL_BASE}/object/res.users?domain=[('company_id','=',${companyId})]&fields=['is_resident']`);
  }

  get_list_documents(companyId: any, userId: number, offset: number, limit: number) {
    return this.http.get(`${environment.URL_BASE}/object/document.user/get_list_document?company_id=${companyId}&user=${userId}&offset=${offset}&limit=${limit}`);
  }

  get_list_document_employe(company_id:number,res_id:number,offset:number,limit:number){
    return this.http.get(`${environment.URL_BASE}/object/document.user/get_list_doc_employee?company_id=${company_id}&res_id=${res_id}&offset=${offset}&limit=${limit}`)
  }

  get_list_mes_document(company_id:number,res_id:number,offset:number,limit:number){
    return this.http.get(`${environment.URL_BASE}/object/document.user/get_list_doc?company_id=${company_id}&res_id=${res_id}&offset=${offset}&limit=${limit}`)
  }
  get_id_employe(res_id:number){
    return this.http.get(`${environment.URL_BASE}/object/hr.employee?domain=[('user_id','=',${res_id})]&fields=['id']`)
  }

  change_document_owner(documentOwner: DocumentOwner) {
    this.documentOwner.next(documentOwner);
  }


  get_documents_residence(companyId: number) {
    return this.http.get(`${environment.URL_BASE}/object/document.document?domain=[('company_id','=',${companyId}), ('user_id','=',False)]`);
  }
  get_documentDownload(documentId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/*');
    return this.http.get(`${environment.URL_BASE}/document_user/${documentId}`, { headers, responseType: 'blob' });
  }

  get_documentEmploye(file_id:number){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/*');
    return this.http.get(`${environment.URL_BASE}/document_employe/${file_id}`,{ headers, responseType: 'blob' })
  }

  addDocumentResidence(name: string, societe: string, type_document: string, attachment: any, description: string) {
    const formData = new FormData();
    formData.append('name', name);

    formData.append('company_id', societe.toString());
    formData.append('type_document', type_document);
    formData.append('attachment', attachment);
    formData.append('description', description);

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.URL_BASE}/object/document.user/create_document`, formData).pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }

adddocument_employe(res_id:number,user_id:number,company_id:number,attachment:any ){
  const formData =new FormData();
  formData.append('create_uid', user_id.toString());
  formData.append('res_id', res_id.toString());
  formData.append('company_id', company_id.toString());
  formData.append('attachment',attachment);

return this.http.post(`${environment.URL_BASE}/object/document.user/add_document_employe`,formData).pipe(
  tap(() => {
    this._refreshNeeded$.next()
  })
);
}

adddocument_staff(user_id:number,company_id:number,attachment:any ){
  const formData =new FormData();
  formData.append('create_uid', user_id.toString());
  formData.append('company_id', company_id.toString());
  formData.append('attachment',attachment);

return this.http.post(`${environment.URL_BASE}/object/document.user/add_document_staff`,formData).pipe(
  tap(() => {
    this._refreshNeeded$.next()
  })
);

}
delete_document_employe(id:number){
  return this.http.delete(`${environment.URL_BASE}/object/ir.attachment/${id}?domain=[('res_model', '=', 'hr.employee')]`).pipe(
    tap(() => {
      this._refreshNeeded$.next()
    })
  );
}

  addDocumentResident(name: string, user_id: number, societe: number, type_document: string, attachment: any) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('user', user_id.toString());
    formData.append('company_id', societe.toString());
    formData.append('type_document', type_document);
    formData.append('attachment', attachment);

    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.URL_BASE}/object/document.user/create_document`, formData).pipe(
      tap(() => {
        this._refreshNeeded$.next()
      })
    );
  }

}
