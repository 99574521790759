// dont change
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TableauContentieuxComponent } from './pages/contetieux/tableau-contentieux/tableau-contentieux.component';
import { DocumentsRoutingModule } from './pages/documents/documents-routing.module';
import { DocumentsModule } from './pages/documents/documents.module';
import { TableauxPointagesComponent } from './pages/pointage/tableaux-pointages/tableaux-pointages.component';
import { ScriptLoaderService } from './providers/_services/script-loader.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule, MatSortModule, MatIconModule } from '@angular/material';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OrderModule } from 'ngx-order-pipe';






const ngxconfig: NgxUiLoaderConfig = 
{
  "bgsColor": "#dcd3d3",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 4,
  "fgsColor": "#4e8dd0",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise-fade-rotating",
  "gap": 24,
  "logoPosition": "center-center",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#4eadf5",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Veuillez patientez...",
  "textColor": "#e2e8ed",
  "textPosition": "center-center",
}
@NgModule({
  declarations: [
    AppComponent,
    // ProfileComponent,
    // DocumentsComponent,
    // DetailBudgetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DocumentsRoutingModule,
    DocumentsModule,
    MatTableModule,
    MatSortModule,
    TranslateModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxconfig),
    NgxUiLoaderHttpModule,
    NgxUiLoaderRouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatIconModule,
    OrderModule
  ],
  exports: [

    MatTableModule,
    MatSortModule
  ],
  // entryComponents: [DetailBudgetComponent],
  providers: [ScriptLoaderService, {provide: LocationStrategy, useClass: HashLocationStrategy},
      {
        provide: LOCALE_ID,
        useValue: "fr-FR"
      }
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]

})
export class AppModule { }
