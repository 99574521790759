import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { DocumentsService } from "src/app/providers/documents.service";

import { isArray } from "util";
import { isEmptyObject } from "src/app/empty-object";

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";
import { HomeService } from "src/app/providers/home.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserInfoService } from "src/app/providers/user.info.service";
import { Documents } from "../document-owner";

import Swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatCheckbox
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { LengthDatatableService } from "src/app/providers/length-datatable.service";

import { FileValidator } from "ngx-material-file-input";
import { Subscription } from "rxjs";
declare const $: any;
declare const swal: any;
declare const autosize: any;

@Component({
  selector: "app-list-documents",
  templateUrl: "./list-documents.component.html",
  styleUrls: ["./list-documents.component.sass"]
})
export class ListDocumentsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ["select", "name", "type_document", "file_name"];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatCheckbox) check: MatCheckbox;
  documents: Document[] = [];
  empty: boolean;
  residentDoc: FormGroup;
  company_id: any;
  attachment: File = null;
  userId: number;
  ownerId: any;
  residentName: string;
  readonly maxSize = 26214400;

  formInvalid: boolean;
  subscription: Subscription;

  lengthTable: number;
  selectedRowIndex: number;

  constructor(
    private documentsService: DocumentsService,
    private homeService: HomeService,

    public route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private table: LengthDatatableService
  ) {
    this.ownerId = this.route.snapshot.paramMap.get("id");
    this.residentName = this.route.snapshot.paramMap.get("name");
    console.log("id========", this.ownerId);
    console.log("name==========", this.residentName);
  }
  typeDocuments = [
    { id: "legal", name: "Juridique" },
    { id: "technical", name: "Technique" },
    { id:'comptable',name:'Comptable'},
    { id: "courrier", name: "Courrier" },
    { id: "divers", name: "Divers" }

  ];

  ngOnInit() {
    this.createForm();

    this.getListDocuments(0, 0, 5);
    this.subscription = this.documentsService.refreshNeeded.subscribe(() => {
      this.getListPage();
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pageEvent(event) {
    const index = event.pageIndex;
    const limit = event.pageSize;
    const offset = index * limit;
    console.log("index limit offset", index, limit, offset);
    this.getListDocuments(index, offset, limit);
  }

  createForm() {
    this.residentDoc = new FormGroup({
      name: new FormControl("", Validators.required),
      typeD: new FormControl("", Validators.required),
      attachment: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          FileValidator.maxContentSize(this.maxSize)
        ])
      ),
      description: new FormControl()
    });
  }
  getListPage() {
    this.getListDocuments(
      this.paginator.pageIndex,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.paginator.pageSize
    );
  }

  getListDocuments(index: number, offset: number, limit: number) {
    this.homeService.get_user_info().subscribe(id => {
      this.company_id = id.company_id;

      this.subscription = this.documentsService
        .get_list_documents(this.company_id, this.ownerId, offset, limit)
        .subscribe(res => {
          this.table
            .get_search_count(
              `[('company_id', '=', ${this.company_id}), ('user_id', '=', ${this.ownerId})]`,
              "document.document"
            )
            .subscribe(res => {
              this.lengthTable = res["count"];
              console.log("length========", res);
            });

          const listRes = res["document.user"];

          if (isEmptyObject(res["document.user"])) {
            this.empty = true;
          } else {
            this.empty = false;

            const list: Array<any> = [];
            if (isArray(listRes)) {
              listRes.forEach(element => {
                const obj = new Documents(
                  element.id,
                  element.company_id,
                  element.ownerId,
                  element.name,
                  element.type_document,
                  element.file_name,
                  element.description
                );
                list.push(obj);
              });
            } else {
              if (!isEmptyObject(listRes)) {
                const obj = new Documents(
                  listRes.id,
                  listRes.company_id,
                  listRes.ownerId,
                  listRes.name,
                  listRes.type_document,
                  listRes.file_name,
                  listRes.description
                );
                list.push(obj);
              }
            }
            console.log("the list contains", list);
            this.documents = list;

            this.dataSource = new MatTableDataSource(list);
            this.dataSource.sort = this.sort;
          }
        });
    });
  }

  onSubmit() {
    console.log("value", this.residentDoc.controls.typeD.value);
    if (this.residentDoc.invalid) {
      this.formInvalid = true;
      console.log("form invalid");
      Swal.fire("", "Formulaire invalide", "error");
    } else if (this.residentDoc.valid) {
      this.formInvalid = false;
      this.ngxService.start();

      this.subscription = this.documentsService
        .addDocumentResident(
          this.residentDoc.controls.name.value,
          this.ownerId,
          this.company_id,
          this.residentDoc.controls.typeD.value,
          this.attachment
        )
        .subscribe(
          data1 => {
            console.log(name);
          },
          err => {
            this.ngxService.stop();
            this.residentDoc.reset();
            setTimeout(() => {
              Swal.fire(
                "",
                "Une erreur est survenue",

                "error"
              );
            }, 500);
          }
        );
      this.ngxService.stop();
      setTimeout(() => {
        Swal.fire("", "Document ajouté avec succes!", "success");
      }, 500);
      this.residentDoc.reset();
    } else {
      console.log("erreur ======");
    }
  }
  annuler() {
    this.residentDoc.reset();
  }
  onSelectedFile(event) {
    this.attachment = event.target.files[0];
    this.residentDoc.controls.attachment = event.target.files[0];
    console.log("onSelectFile", this.residentDoc.controls.attachment);
    console.log("onSelectFile=======", this.attachment);
  }

  print() {
    this.selection.selected.forEach(element => {
      console.log("elementto print =======", element);

      this.documentsService.get_documentDownload(element.id).subscribe(data => {
        const urlDownload = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = urlDownload;
        link.download = element.file_name;
        link.click();
      });
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
