import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { DocumentsComponent } from './documents.component';

const routes: Routes = [
  {
    path: 'documents',
    redirectTo: 'suivi_document',
    pathMatch: 'full'
  },
  {
    path: 'suivi_document',
    component: DocumentsComponent
  },
  {
    path: 'list_documents/:id/:name',
    component: ListDocumentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
