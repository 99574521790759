const unauthorizedPagesOwner = [
    '/comptabilite_finance/detail_gestion_budgetaire',
    '/comptabilite_finance/edit_ordre_paiement',
    '/comptabilite_finance/tableau_recouvrement',
    '/comptabilite_finance/rapport',
    '/comptabilite_finance/etat_caisse',
    '/comptabilite_finance/tableau_recouvrement/detail-resident',
    '/comptabilite_finance/rapport_journalier',
    '/contentieux/tableau_contentieux',
    '/pointage/tableau_pointages',
    '/pointage/attribution_conge_autorisation',
    '/pointage/autorisation',
    '/pointage/suivi_conge',
    '/pointage/dossier',
    '/pointage/interface_pointage',
    '/pointage/listes_taches',
    '/pointage/planning',
    '/pointage/suivi-list-taches',
    '/pointage/suivi_liste_autorisation',
    '/pointage/suivi_liste_conge',
    '/profile/gestion_profile'
];

export default unauthorizedPagesOwner;

