export class DocumentOwner {
    companyId: number;
    ownerId: number;
    constructor(companyId: number,
        ownerId: number) {
            this.companyId = companyId;
            this.ownerId = ownerId;
        }
}


export class Documents {
    id: number;
    companyId: number;
    ownerId: number;
    name: string;
    type_document: any;
    file_name: any;
    description: string;


    constructor(id: number, companyId: number,
        ownerId: number,  name: string,type_document: any, file_name: any, description: string) {
            this.id =id;
            this.companyId = companyId;
            this.ownerId = ownerId;
            this.name = name;
            this.type_document = type_document;
            this.file_name = file_name;
            this.description = description
        }
}