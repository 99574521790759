import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { MatRadioButton, MatTableModule, MatSortModule, MatSelectModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModules } from 'src/app/material.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';



@NgModule({
  declarations: [DocumentsComponent, ListDocumentsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModules
]
})

export class DocumentsModule { }
