import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service';
import { HomeService } from 'src/app/providers/home.service';
import unauthorizedPagesOwner from '../unauthorized-pages-by-role/unauthorized-pages-owner';
import unauthorizedPagesStaff from '../unauthorized-pages-by-role/unauthorized-pages-staff';
import { firstLogin } from '../unauthorized-pages-by-role/increment';
import { RoleUser } from 'src/app/user-info-model';

@Injectable({
  providedIn: 'root'
})
export class HomeGuardGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthServiceService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private home: HomeService) {
              }
  // static n = 0;
  state: RouterStateSnapshot;
  pathReset:string = '/authentication/new-password';

  // static firstLogin() {
  //   return HomeGuardGuard.n ++;
  // }
  // static firstLogout() {
  //   HomeGuardGuard.n = 0;
  // }
  canActivate() {
    return this.canLoad();
  }

  canLoad( ) {
    console.log("this url ========",this.router.url);
    console.log(' static number ::::: ', firstLogin());
    if (!this.authService.isLoggedIn()) {
      if (this.router.url !== this.pathReset) {
         this.router.navigate(['/authentication/home_page']);
      } else {
        this.router.navigate([this.pathReset]);
      }
    } else {
      this.verifyAuthorizedRoutes();
      if (firstLogin() <= 1) {
        this.changeRouteAfterLogin();
      }
    }
    return this.authService.isLoggedIn();
  }

  changeRouteAfterLogin() {
    this.home.get_user_info().subscribe(
      user => {
        console.log('user guard :::: ', user);
        if (user.role === 'owner') {
           this.router.navigate(['/resident/residents']);
        } else if (user.role === 'staff') {
          this.router.navigate(['/pointage/interface_pointage']);
        }
         else if(user.role ==RoleUser.back_office || user.role == RoleUser.owner_president ||user.role== RoleUser.owner_treasury || user.role==RoleUser.admin || user.role== RoleUser.front_office_recovery){
          this.router.navigate(['/comptabilite_finance/tableau_recouvrement']);
        }
        else if (user.role == RoleUser.front_office_technical){
          this.router.navigate(['/reclamations_demandes/suivi_les_reclamations']);       
         }
      }
    );
  }

  verifyAuthorizedRoutes() {
    console.log('verifyAuthorizedRoutes');
    this.home.get_user_info().subscribe(
      user => {
        console.log('this.router.url :::: ', this.router.url);
        if (user.role === 'owner') {
          if (unauthorizedPagesOwner.includes(this.router.url)) {
            console.log('not authorized !!!!!!!!!!!!!!');
            this.router.navigate(['/authentication/page404']);
          }
        } else if (user.role === 'staff') {
          if (unauthorizedPagesStaff.includes(this.router.url)) {
            console.log('not authorized !!!!!!!!!!!!!!');
            this.router.navigate(['/authentication/page404']);
          }
        } 
      }
    );
  }
}
