import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { DocumentsService } from "../../providers/documents.service";
import { UserInfoService } from "src/app/providers/user.info.service";
import { Router } from "@angular/router";
import { DocumentOwner } from "./document-owner";
import { RoleUser, UserInfoModel } from "../../../app/user-info-model";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatCheckbox
} from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { HomeService } from "src/app/providers/home.service";
import { isArray } from "util";
import { isEmptyObject } from "src/app/empty-object";
import { LengthDatatableService } from "../../providers/length-datatable.service";
import { combineLatest, Subscription, Subject } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { randomString } from "../../randomString";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.sass"]
})
export class DocumentsComponent implements OnInit ,OnDestroy{
  displayedColumns: string[] = ["select", "image", "name","Aprt","cin", "phone","adresse", "email"];
  public destroyed:Subject<boolean>=new Subject<boolean>();
  dataSource = new MatTableDataSource<any>();
  isResident: boolean;
  role: string;
  companyId: number;
  loader: boolean;
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatCheckbox) check: MatCheckbox;
  image: string;
  lengthTable: any;
  randomString: string;
  length: any;
 

  constructor(
    public userinfo: UserInfoService,
    private homeService: HomeService,
    private serviceDocument: DocumentsService,
    public router: Router,
    private lengthTableService: LengthDatatableService
  ) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getList(0, 10, 0);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getList(index: number, size: number, offset: number) {
    this.userinfo.currentInfoUser.pipe(takeUntil(this.destroyed)).subscribe(user => {
      if (user === null) {
        console.log("null user ====");
        this.homeService
          .get_user_info()
          .pipe(takeUntil(this.destroyed))
          .subscribe(data => {
            const user = new UserInfoModel(
              data.id,
              data.name,
              data.email,
              data.company_id,
              data.company_name,
              data.company_ids,
              data.role,
              data.address,
              data.phone,
              data.city,
              data.zip
            );
            this.listResidents(index, size, offset, data);
          });
      } else {
        this.listResidents(index, size, offset, user);
      }
    });
  }

  listResidents(index: number, size: number, offset: number, data) {
    if (data.role !== RoleUser.admin && data.role !== RoleUser.back_office ) {
      this.router.navigate(["/documents/list_documents", data.id, data.name]);
    } else {
      this.serviceDocument
        .get_users_documents(data.company_id, offset, size).pipe(takeUntil(this.destroyed))
        .subscribe(res => {
          this.lengthTableService
            .get_search_count(
              `[('company_id','=',${data.company_id}), ('is_resident','=',True)]`,
              "res.users"
            )
            .pipe(takeUntil(this.destroyed)).subscribe(len => {
              this.length = len["count"];
            });

          const listRes = res["res.users"];
          console.log("listRes=========", listRes);
          console.log("length=========", this.length);

          this.lengthTable = length;
          const list: Array<any> = [];
          if (isArray(listRes)) {
            listRes.forEach(element => {
              this.randomString = randomString();

              const obj = new UserInfoModel(
                element.id,
                element.name,
                element.email,
                element.company_id,
                element.company_name,
                element.company_ids,
                element.role,
                element.address,
                element.phone,
                element.city,
                element.zip
              );
              obj.imageUrl = `${environment.URL_BASE}/image_user/${element.id}/${this.randomString}`;
              obj.cin = element.cin;
              obj.apartement_list_ids_get = element.apartement_list_ids_get.replace('  ',', ')
              console.log("image==========", obj);
              obj.address = element.street + " " + element.city + " " + element.zip
              list.push(obj);
            });
          } else {
            if (!isEmptyObject(listRes)) {
              const obj = new UserInfoModel(
                listRes.id,
                listRes.name,
                listRes.email,
                listRes.company_id,
                listRes.company_name,
                listRes.company_ids,
                listRes.role,
                listRes.address,
                listRes.phone,
                listRes.city,
                listRes.zip
              );
              obj.imageUrl = `${environment.URL_BASE}/image_user/${listRes.id}/${this.randomString}`;

              list.push(obj);
            }
          }
          this.lengthTable = this.length;
          this.paginator.length = this.length;
          this.paginator.pageIndex = index;
          this.paginator.pageSize = size;
          this.dataSource = new MatTableDataSource(list);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'Aprt': {
                let aprt= new String(item.apartement_list_ids_get);
                return aprt;
                }
          
              case 'adresse': {
                  let adres= new String(item.address);
                  return adres;
              }
              case 'email': {
                    let email= new String(item.email);
                return email;
            }
             
              default: {
                return item[property];}
                      }
                  };
      });
 
    }
    
  }

  pageEvent(event) {
    const index = event.pageIndex;
    const size = event.pageSize;
    const offset = index * size;
    this.getList(index, size, offset);
    console.log("page event :: ", event);
  }

  details() {
    const documentOwner = new DocumentOwner(
      this.companyId,
      this.selection.selected[0].id
    );
    this.serviceDocument.change_document_owner(documentOwner);
    this.router.navigate([
      "/documents/list_documents",
      this.selection.selected[0].id,
      this.selection.selected[0].name
    ]);
  }
  ngOnDestroy() {
    this.destroyed.next(true);
  this.destroyed.complete() }
}
