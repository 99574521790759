import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './pages/profile/profile.component';
// import { DocumentsComponent } from './pages/documents/documents.component';
import { HomeGuardGuard } from './auth/guards/home-guard.guard';

const routes: Routes = [

    {
        path: 'comptabilite_finance',
        loadChildren: './pages/comptabilite-finance/comptabilite-finance.module#ComptabiliteFinanceModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'contentieux',
        loadChildren: './pages/contetieux/contetieux.module#ContetieuxModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfileModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'pointage',
        loadChildren: './pages/pointage/pointage.module#PointageModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
 
    {
        path: 'documents',
        loadChildren: './pages/documents/documents.module#DocumentsModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'reclamations_demandes',
        loadChildren: './pages/reclamation-demandes/reclamation-demandes.module#ReclamationDemandesModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    },
    {
        path: 'authentication',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
    },
    {
        path: 'resident',
        loadChildren: './pages/resident/resident.module#ResidentModule',
     
      },
      {
        path: 'residences',
        loadChildren: './pages/residences/residences.module#ResidencesModule',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
      },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [HomeGuardGuard],
        canLoad: [HomeGuardGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
      })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
