import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInteceptorsService } from './token-inteceptors.service';
import { AuthServiceService } from './services/auth-service.service';

@NgModule({
  declarations: [],
  providers: [
    AuthServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInteceptorsService,
      multi: true    }
  ],
  imports: [
    CommonModule,
    HttpClientModule
    ]
})
export class AuthModule { }
