import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Reclamation, Demande } from '../pages/reclamation-demandes/models/reclamation-demande';
import { environment } from "../../environments/environment";
import {
  ReclamationDemande,
  Rec
} from "../pages/reclamation-demandes/models/reclamation-demande";
import { Subject, Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { pipe } from "@angular/core/src/render3";
import { Ticket } from '../pages/reclamation-demandes/models/demande';

@Injectable({
  providedIn: "root"
})
export class ReclamationService {
  constructor(private http: HttpClient) {}

  _refreshNeeded$ = new Subject<void>();

  get refreshNeeded() {
    return this._refreshNeeded$;
  }
  get_list_reclamation(domain: any, offset: number, limit: number) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket?domain=${domain}&fields=['person_name','subject', 'state', 'support_comment', 'create_date', 'close_time', 'close_comment','ticket_number','create_uid','attachment','attachment_filename','attachment_id']&offset=${offset}&limit=${limit}`
    );
  }
  get_list_count(domain: any) {
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket/search_count?domain=${domain}`
    );
  }
  get_list_demande(domain: any, offset: number, limit: number) {
    // tslint:disable-next-line:max-line-length

    return this.http
      .get(`${environment.URL_BASE}/object/support.ticket?domain=${domain}&fields=['person_name','subject', 'state',
     'support_comment', 'create_date', 'close_time',
     'close_comment','ticket_number','create_uid','attachment',
     'attachment_filename','attachment_id']&offset=${offset}&limit=${limit}`);
  }

  get_list_attachment(ids: any, domain: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      `${environment.URL_BASE}/object/ir.attachment?ids=${ids}&domain=${domain}`
    );
  }
  update_state_reclamation(id: number, state: string) {
    return this.http
      .put(
        `${environment.URL_BASE}/object/support.ticket/${id}?vals={"state": "${state}"}`,
        null
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  valider_ticket(id: number, statut: string) {
    return this.http
      .post(
        `${environment.URL_BASE}/object/support.ticket/valider_ticket?id=${id}&statut='${statut}'`,
        null
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
  update_state_demande(id: number, state: string) {
    return this.http
      .post(
        `${environment.URL_BASE}/object/support.ticket/${id}?vals={"state": "${state}"}`,
        null
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
  get_rec_dem(domain: any) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket?domain=${domain}&fields=['person_name','subject','create_date' ,'ticket_type', 'create_uid','state','ticket_number']`
    );
  }

  delete_ticket(id: any) {
    return this.http
      .delete(`${environment.URL_BASE}/object/support.ticket?ids=${id}`)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
  get_priority() {
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket.priority?fields=['name']`
    );
  }

  get_categoryrec() {
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket.categories?domain=[('name','not in',['Travaux','Évènements'])]&fields=['name']`
    );
  }
  get_categorydem() {
    return this.http.get(
      `${environment.URL_BASE}/object/support.ticket.categories?domain=[('name','in',['Paiement','Travaux','Évènements','Autres'])]&fields=['name']`
    );
  }
  get_bloc_list(companyId: number) {
    return this.http.get(
      `${environment.URL_BASE}/object/bloc.list?fields=['name']&domain=[('company_id', '=', ${companyId})]`
    );
  }

  get_floor_list(blocListId: number) {
    return this.http.get(
      `${environment.URL_BASE}/object/floor.list?fields=['name']&domain=[('bloc_list_id', '=', ${blocListId})]`
    );
  }

  create_reclamation(reclamation: Ticket) {
    const formData = new FormData();
    formData.append("subject", reclamation.subject);
    formData.append("ticket_type", reclamation.ticket_type);
    formData.append("place", reclamation.place);
    formData.append("priority_id", String(reclamation.priority_id.toString()));
    formData.append("support_comment", reclamation.support_comment);
    formData.append("category", String(reclamation.category.toString()));
    formData.append("bloc", String(reclamation.bloc.toString()));
    formData.append("floor", String(reclamation.floor.toString()));
    formData.append("email", reclamation.email);
    formData.append("person_name", reclamation.person_name);
    formData.append("company_id", String(reclamation.company_id.toString()));
    formData.append("attachment", reclamation.file);
    //formData.append('state', reclamation.);
    // const vals = JSON.stringify(reclamation);
    return this.http
      .post(
        `${environment.URL_BASE}/object/support.ticket/create_request`,
        formData
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }

  create_demande(demande: Ticket) {
    const formData = new FormData();
    formData.append("subject", demande.subject);
    formData.append("support_comment", demande.support_comment);
    formData.append("category", demande.category);
    formData.append("email", demande.email);
    formData.append("person_name", demande.person_name);
    formData.append("company_id", String(demande.company_id.toString()));
    formData.append("attachment", demande.file);
    return this.http
      .post(
        `${environment.URL_BASE}/object/support.ticket/create_demande`,
        formData
      )
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
  add_devis(rec: Rec) {
    const formData = new FormData();
    formData.append("id", rec.id);

    formData.append("state", rec.state);
    formData.append("attachment", rec.file);
    return this.http
      .post(`${environment.URL_BASE}/object/support.ticket/addDevis`, formData)
      .pipe(
        tap(() => {
          this._refreshNeeded$.next();
        })
      );
  }
  get_demandeDownload(object: any, demandeId: number, id: number) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/*");
    return this.http.get(
      `${environment.URL_BASE}/${object}/${demandeId}?id=${id}`,
      { headers, responseType: "blob" }
    );
  }
}
