import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserInfoModel } from "../user-info-model";

@Injectable({
  providedIn: "root"
})
export class UserInfoService {
  public attachement: Subject<string | ArrayBuffer> = new BehaviorSubject<
    string | ArrayBuffer
  >(null);
  public userId: Subject<number> = new BehaviorSubject<number>(0);
  public companyId: Subject<number> = new BehaviorSubject<number>(0);
  public userName: Subject<string> = new BehaviorSubject<string>("user");
  public userMail: Subject<string> = new BehaviorSubject<string>(
    "user@user.com"
  );
  public companyName: Subject<string> = new BehaviorSubject<string>("Luxus");
  public infoUser: Subject<UserInfoModel> = new BehaviorSubject<UserInfoModel>(
    null
  );
  currentUserId = this.userId.asObservable();
  currentCompanyId = this.companyId.asObservable();
  currentUserName = this.userName.asObservable();
  currentCompanyName = this.companyName.asObservable();
  currentUserMail = this.userMail.asObservable();
  currentInfoUser = this.infoUser.asObservable();
  currentAttachement = this.attachement.asObservable();
  constructor() {}

  changeAttachement(attachement: string | ArrayBuffer) {
    this.attachement.next(attachement);
  }

  changeUserId(id: number) {
    this.userId.next(id);
  }

  changeCompanyId(companyId: number) {
    this.companyId.next(companyId);
  }

  changeUserName(name: string) {
    this.userName.next(name);
  }

  changeUserMail(mail: string) {
    this.userMail.next(mail);
  }

  changeCompanyName(companyName: string) {
    this.companyName.next(companyName);
  }

  changeInfoUser(userInfo: UserInfoModel) {
    this.infoUser.next(userInfo);
  }

  InfoUserAfterLogout() {
    this.infoUser.next();
    this.infoUser.complete();
  }
  changeInfoCompanyUser(companyId: number, companyName: string) {
    this.currentInfoUser.pipe(take(1)).subscribe(user => {
      // tslint:disable-next-line:max-line-length
      const userInfo = new UserInfoModel(
        user.id,
        user.name,
        user.email,
        companyId,
        companyName,
        user.company_ids,
        user.role,
        user.address,
        user.phone,
        user.city,
        user.zip
      );
      this.infoUser.next(userInfo);
    });
  }
}
